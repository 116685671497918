@tailwind base;
@tailwind components;
@tailwind utilities;
.text-gradient {
  background: linear-gradient(90deg, #e66465, #9233ea);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
/* custom.css */
.radio-green input[type="radio"]:checked {
  background-color: #10b981; /* Tailwind's green-500 color */
  border-color: #10b981;
}

.radio-green input[type="radio"] {
  appearance: none;
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid #d1d5db; /* Tailwind's gray-300 color */
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  transition: background 0.2s, border-color 0.2s;
}

.radio-green input[type="radio"]:checked::after {
  content: "";
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background: #10b981; /* Tailwind's green-500 color */
  margin: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@layer components {
  .border-gradient {
    position: relative;
    border: 2px solid transparent;
    border-radius: 1rem;
    background-clip: padding-box;
  }
  .border-gradient::before {
    content: "";
    position: absolute;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
    z-index: -1;
    border-radius: 1rem;
    background: linear-gradient(
      to right,
      #ec4899,
      #8b5cf6
    ); /* Pink and purple gradient */
  }
}

.custom-radio {
  appearance: none;
  background-color: #fff;
  border: 1px solid #ccc;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
}

.custom-radio:checked::before {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: green;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.react-calendar {
  width: 100%;
  border: none;
  font-family: "Inter", sans-serif;
}

.react-calendar__navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  font-size: 1.25rem;
  font-weight: bold;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.react-calendar__month-view__weekdays {
  display: flex;
  justify-content: space-around;
  font-weight: bold;
  color: #6b7280; /* Tailwind gray-500 */
  padding: 0.5rem 0;
}

.react-calendar__month-view__days__day {
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  cursor: pointer;
}

.react-calendar__tile--now {
  background: none;
}

.react-calendar__tile--active {
  background: linear-gradient(
    to bottom right,
    #9333ea,
    #f472b6
  ); /* Tailwind purple-600 to pink-400 */
  color: white;
  border-radius: 50%;
}

.react-calendar__tile--active:hover {
  background: linear-gradient(
    to bottom right,
    #9333ea,
    #f472b6
  ); /* Tailwind purple-600 to pink-400 */
}

.highlight {
  background: linear-gradient(
    to bottom right,
    #9333ea,
    #f472b6
  ); /* Tailwind purple-600 to pink-400 */
  color: white;
  border-radius: 50%;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #d1d5db; /* Tailwind gray-300 */
}
/* Add this CSS to your global CSS file */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-slide-in {
  animation: slide-in 0.3s ease-in-out;
}

/* Tailwind CSS classes for spinner animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
}
.ql-toolbar .ql-separator {
  display: inline-block;
  width: 1px;
  background-color: #ccc;
  margin: 0 8px;
  height: 24px;
}

.custom-calendar .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
  display: none;
}

.custom-calendar .ant-picker-calendar .ant-picker-calendar-date-content {
  border: 1px solid #eaeaea; /* Adds border to each cell */
}

.ant-picker-calendar-header .ant-picker-calendar-month-btn {
  text-transform: capitalize;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(100, 100, 100, 0.5);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(100, 100, 100, 0.8);
}
